.root {
    padding: 20vh 36px;
  }
  .header {
    height: var(--topbarHeight);
  }
  .content {
    text-align: center;
    max-width: 650px;
    margin: 0 auto;
  }