@import '../../styles/customMediaQueries.css';

.root {
  display: block;

  flex-shrink: 0;
  padding: 0;
  height: 100%;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    padding: 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}